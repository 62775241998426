import type * as Apollo from '@apollo/client';
import {
  GetIntercomHashDocument,
  TGetIntercomHashQueryResult,
  TGetIntercomHashQueryVariables,
} from '@root/graphql/generated/operations';

type Result = TGetIntercomHashQueryResult;
type Variables = TGetIntercomHashQueryVariables;
type Options = Omit<Apollo.QueryOptions<TGetIntercomHashQueryVariables>, 'query'>;
const document = GetIntercomHashDocument;

export const getIntercomHashPromise = async (
  client: Apollo.ApolloClient<object>,
  baseOptions?: Options,
): Promise<Apollo.ApolloQueryResult<Result>> => {
  const result = await client.query<Result, Variables>({
    query: document,
    ...baseOptions,
  });

  return result;
};
