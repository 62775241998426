import type * as Apollo from '@apollo/client';
import {
  GetAssemblyProjectDocument,
  CreateMediaDocument,
  TCreateMediaMutationResult,
  TCreateMediaMutationVariables,
} from '@root/graphql/generated/operations';

type Result = TCreateMediaMutationResult;
type Variables = TCreateMediaMutationVariables & { progressText?: string };
type Options = Omit<Apollo.MutationOptions<Result, Variables>, 'mutation'>;
const document = CreateMediaDocument;

export const createMediaPromise = async (
  client: Apollo.ApolloClient<object>,
  baseOptions?: Options,
): Promise<Apollo.FetchResult<Result>> => {
  const result = await client.mutate<Result, Variables>({
    mutation: document,
    ...baseOptions,
  });

  client?.cache.updateQuery(
    {
      query: GetAssemblyProjectDocument,
      variables: {
        projectId: baseOptions?.variables?.projectId,
      },
    },
    (existedCache) => {
      if (!result?.data?.createMedia) return existedCache;

      return {
        getAssemblyProject: {
          ...existedCache?.getAssemblyProject,
          imported_media: [
            ...existedCache?.getAssemblyProject?.imported_media,
            { ...result?.data?.createMedia, progressText: baseOptions?.variables?.progressText || '' },
          ],
        },
      };
    },
  );

  return result;
};
