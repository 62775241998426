import type * as Apollo from '@apollo/client';
import {
  TCreateMultipartPreSignedUrlsMutationResult,
  TCreateMultipartPreSignedUrlsMutationVariables,
  CreateMultipartPreSignedUrlsDocument,
} from '@root/graphql/generated/operations';

type Result = TCreateMultipartPreSignedUrlsMutationResult;
type Variables = TCreateMultipartPreSignedUrlsMutationVariables;
type Options = Omit<Apollo.MutationOptions<Result, Variables>, 'mutation'>;
const document = CreateMultipartPreSignedUrlsDocument;

export const createMultipartPreSignedUrlsPromise = async (
  client: Apollo.ApolloClient<object>,
  baseOptions?: Options,
): Promise<Apollo.FetchResult<Result>> => {
  const result = await client.mutate<Result, Variables>({
    mutation: document,
    ...baseOptions,
  });

  return result;
};
