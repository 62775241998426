import React, { createContext, useEffect, useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { Uploader } from '@root/utils/uploader';

import {
  TProcessTranslateMultipleMediaSubscriptionResult,
  TOnMediaStatusChangedSubscriptionResult,
  TGetAssemblyProjectQueryResult,
  TypeInputFile,
  MediaAction,
  MediaStatus,
  TMedia,
  CoreMediaFieldsFragmentDoc,
  GetAssemblyProjectDocument,
  useGetAssemblyMediaLazyQuery,
  useGetAssemblyProjectLazyQuery,
  useGetStatusTranscribeLazyQuery,
  useChargeCreditMutation,
  useCreateMediaMutation,
  useCanceledImportMediaMutation,
  useCreateSignUrlAwsMutation,
  useUploadImportMediaIntoProjectMutation,
  useRetrievedTranscribeMutation,
  useTranscribeMediaMutation,
  useTranslateMediaMutation,
  useUpdateStatusFailUploadMutation,
  useOnMediaStatusChangedSubscription,
  useGetLanguagesQuery,
  useNotifySeverMediaUploadToMuxMutation,
} from '@root/graphql/generated/operations';

import { createMediaPromise, createSignUrlAwsPromise, getAssemblyMediaPromise } from '@root/graphql/operations';

const initialState = {
  lastTranscribe: undefined as LanguageRegion | undefined,
  mediasState: [] as ImportedMedia[],
  accessTokenGD: '',
  useMuxService: true,
  handleLoadFiles: (arg0: FileMetadata[]) => {},
  handleUploadFilesWithMux: (arg0: FileMetadata[]) => {},
  setAccessTokenGD: (arg0: string) => {},
  onSubTranslateFile: (arg0: TProcessTranslateMultipleMediaSubscriptionResult['processTranslateMultipleMedia']) => {},
  cancelUploadingMedia: (media: ImportedMedia) => {},
  onStartTranscribe: (media: ImportedMedia) => {},
  setListFileMedia: (medias: ImportedMedia[]) => {},
  onStartTranslate: (arg0: ImportedMedia, listTranslate: Language[]) => {},
  onTranscribeDone: (arg0: ImportedMedia) => {},
  setLastTranscribe: (arg0: LanguageRegion) => {},
  onAmendMedia: (arg0: string, arg1: string | null) => {},
};
const sideBarStore = createContext(initialState);
const { Provider } = sideBarStore;

type SideBarProviderProps = {
  children: any;
};

const MAXIMUM_PROGRESS_PERCENTAGE = 99;

const SideBarProvider = ({ children }: SideBarProviderProps) => {
  const client = useApolloClient();
  const router = useRouter();
  const { projectid } = router.query;
  const projectId = projectid as string;

  const [mediasState, setMediasState] = useState<ImportedMedia[]>([]);
  const [accessTokenGD, setAccessTokenGD] = useState('');
  const [lastTranscribe, setLastTranscribe] = useState<LanguageRegion>();
  const [useMuxService] = useState(true);
  // const [listLanguageRegion, setListLanguageRegion] = useState<LanguageRegion[]>([]);

  // const whisperLanguageRegion = useMemo(() => {
  //   const foundService = listLanguageRegion?.find((item) => item.defaultSpeechApi === '7');
  //   return foundService;
  // }, [listLanguageRegion]);

  // const { data: languagesData } = useGetLanguagesQuery();

  const [, { data: assemblyMediaData, refetch: refetchAssemblyMedia }] = useGetAssemblyMediaLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [updateUploadedMedia] = useUploadImportMediaIntoProjectMutation({
    onCompleted: (data) => {
      const media = data.uploadImportMediaIntoProject;

      updateMediaByMediaId(media.id, {
        percentage: 51,
        status: MediaStatus.Transcoding,
        progressText: MediaStatus.Transcoding,
      });
    },
  });

  const [notifySeverMediaUploadToMux] = useNotifySeverMediaUploadToMuxMutation({
    onCompleted: (data) => {
      const media = data.notifySeverMediaUploadToMux;
      if (!media) return;

      // const interval = startFakeTranscodeProcessInterval(media);

      updateMediaByMediaId(media.id, {
        // ...(!interval ? { percentage: MAXIMUM_PROGRESS_PERCENTAGE } : { percentage: 0 }),
        // fakeTranscodeProgressInterval: interval,
        progressText: MediaStatus.Transcoding,
        status: MediaStatus.Transcoding,
      });
    },
  });

  const [deleteFileUpload] = useCanceledImportMediaMutation({
    onCompleted: (data) => {
      const mediaId = data?.canceledImportMedia?.id;
      if (mediaId) deleteMediaById(mediaId);
    },
    update(cache, { data }) {
      const deletedMedia = data?.canceledImportMedia;

      const cacheIdentifier = cache.identify({
        __typename: 'Media',
        id: deletedMedia?.id,
      });

      cache.modify({
        id: cacheIdentifier,
        fields: {
          archived(cacheValue: string, context: any) {
            return deletedMedia?.archived || cacheValue; // it should be true
          },
        },
      });
    },
  });

  const [startTranscribe] = useTranscribeMediaMutation();

  // const [chargeCredit] = useChargeCreditMutation({
  //   onCompleted: (data) => {
  //     if (!data?.chargeCredit || !data?.chargeCredit?.media) return;

  //     const currentFileImport = mediasState.find((item) => item.id === data.chargeCredit?.media?.id);
  //     const response = data.chargeCredit;

  //     if (!response || !response.media || !currentFileImport) return;

  //     if (data.chargeCredit.errorStatus) {
  //       // updateMediaByMediaId(response.media.id, {
  //       //   isChargeFail: true,
  //       // });

  //       return;
  //     }

  //     if (!response?.language || !response.defaultSpeechApi) return;

  //     // updateMediaByMediaId(response.media.id, {
  //     //   creditCharged: data.chargeCredit.creditCharged,
  //     //   cardId: data.chargeCredit.cardId,
  //     //   isCheckCharge: false,
  //     // });

  //     if (response.media.status === MediaStatus.TranscribePaid) {
  //       const interval = startFakeTranscribeProcessInterval(response.media);

  //       updateMediaByMediaId(response.media.id, {
  //         ...(!interval ? { percentage: MAXIMUM_PROGRESS_PERCENTAGE } : { percentage: 0 }),
  //         fakeTranscribeProgressInterval: interval,
  //         progressText: MediaStatus.Transcribing,
  //         status: MediaStatus.Transcribing,
  //       });

  //       startTranscribe({
  //         variables: {
  //           mediaId: response.media?.id,
  //           language: response.language,
  //           defaultSpeechApi: response.defaultSpeechApi,
  //         },
  //       });
  //     }
  //   },
  // });

  const [updateStatusMediaFail] = useUpdateStatusFailUploadMutation();

  useOnMediaStatusChangedSubscription({
    variables: { projectId: projectId },
    onData: (options) => {
      const { data } = options.data;
      const client = options.client;
      console.log({ data });
      if (!data?.onMediaStatusChanged) return;

      const { mediaId, status } = data.onMediaStatusChanged;

      const cacheIdentifier = client.cache.identify({
        __typename: 'Media',
        id: mediaId,
      });

      const media = client.cache.readFragment<TMedia>({
        id: cacheIdentifier,
        fragment: CoreMediaFieldsFragmentDoc,
      });
      console.log(media?.status, status);
      if (media?.status !== status) {
        client.cache.modify({
          id: cacheIdentifier,
          fields: {
            status(cacheValue: string, context: any) {
              return status;
            },
          },
        });
      }

      if (media && status === MediaStatus.Transcoding) {
        // onTranscodingProgress(data.onMediaStatusChanged);
        // const interval = startFakeTranscodeProcessInterval(media);
        // updateMediaByMediaId(media.id, {
        //   ...(!interval ? { percentage: MAXIMUM_PROGRESS_PERCENTAGE } : { percentage: 0 }),
        //   fakeTranscodeProgressInterval: interval,
        //   progressText: MediaStatus.Transcoding,
        //   status: MediaStatus.Transcoding,
        // });
      } else if (status === MediaStatus.Ready && media) {
        updateMediaByMediaId(media.id, {
          percentage: 0,
          progressText: MediaStatus.Transcribing,
          status: MediaStatus.Transcribing,
        });

        onStartTranscribe(media);
      }
      // TODO: wait for add authorization token from Transcoder instance
      // else if (status === MediaStatus.Transcribing && media) {
      //   const interval = startFakeTranscribeProcessInterval(media);

      //   updateMediaByMediaId(media.id, {
      //     ...(!interval ? { percentage: MAXIMUM_PROGRESS_PERCENTAGE } : { percentage: 0 }),
      //     fakeTranscribeProgressInterval: interval,
      //     progressText: MediaStatus.Transcribing,
      //     status: MediaStatus.Transcribing,
      //   });
      // }
      else if (status === MediaStatus.Transcribing && media) {
        // clearTranscodeIntervalMedia(mediaId);
        updateMediaByMediaId(media.id, {
          // percentage: 0,
          progressText: MediaStatus.Transcribing,
          status: MediaStatus.Transcribing,
        });
      } else if (status === MediaStatus.Transcribed) {
        // TODO: should update cache instead of request api
        clearTranscribeIntervalMedia(mediaId);
        refetchAssemblyMedia({ mediaId }); // get transcription
        updateMediaByMediaId(mediaId, {
          percentage: 100,
          progressText: status,
          status,
        });
      }
    },
  });

  useEffect(() => {
    const handleUnload = (event: any) => {
      event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit?');
    };

    const hasAnyLocalFilesAreUploading = mediasState.some(
      (media) => media.file_upload_type === TypeInputFile.Local && media.status === MediaStatus.Uploading,
    );

    if (hasAnyLocalFilesAreUploading) {
      window.addEventListener('beforeunload', handleUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [mediasState]);

  // useEffect(() => {
  //   const languagesDataTemp = languagesData?.getLanguages;

  //   if (!languagesDataTemp || !languagesDataTemp.languages || languagesDataTemp.languages.length === 0) {
  //     return;
  //   }

  //   languagesDataTemp.languages.map((language) => {
  //     if (!language?.languageRegions) return;

  //     language.languageRegions.map((region) => {
  //       setListLanguageRegion((prev) =>
  //         prev.concat({
  //           ...(region as LanguageRegion),
  //           language: language as Language,
  //         }),
  //       );
  //     });
  //   });

  //   if (!languagesDataTemp.lastUsedTranscriptionLanguageRegion) return;

  //   const latestTranscription = languagesDataTemp.lastUsedTranscriptionLanguageRegion;

  //   setLastTranscribe(latestTranscription as LanguageRegion);
  // }, [languagesData]);

  function calculateExpectedTime(mediaDurationInSeconds: number, type: 'audio' | 'video') {
    const timeAudioToCompleteRatio = 0.25;
    const timeVideoToCompleteRatio = 0.25;
    const timeToCompleteRatio = type === 'audio' ? timeAudioToCompleteRatio : timeVideoToCompleteRatio; // slower
    const timeToCompleteProcessInSeconds = mediaDurationInSeconds * timeToCompleteRatio;

    return timeToCompleteProcessInSeconds;
  }

  function startFakeTranscribeProcessInterval(media: ImportedMedia) {
    const duration = calculateExpectedTime(media.durationInSeconds || 0, media.type);
    let currentPercentage = media.percentage || 0;
    let remainingTime = duration;

    if (media?.start_time_transcription) {
      const diffNowAndUploadedTime = moment().diff(moment(media.start_time_transcription), 'seconds');

      remainingTime -= diffNowAndUploadedTime;
      if (remainingTime <= 0) remainingTime = 0;

      currentPercentage = diffNowAndUploadedTime * (MAXIMUM_PROGRESS_PERCENTAGE / remainingTime);
      if (currentPercentage >= MAXIMUM_PROGRESS_PERCENTAGE) currentPercentage = MAXIMUM_PROGRESS_PERCENTAGE;

      updateMediaByMediaId(media.id, {
        ...media,
        progressText: MediaStatus.Transcribing,
        percentage: Math.round(currentPercentage),
      });
    }

    if (remainingTime <= 0 || currentPercentage >= MAXIMUM_PROGRESS_PERCENTAGE) return;

    const timeToCompleteProcessInSeconds = duration;

    if (timeToCompleteProcessInSeconds <= 0) return;

    const tick = (1 / MAXIMUM_PROGRESS_PERCENTAGE) * timeToCompleteProcessInSeconds * 1000;

    const interval = setInterval(() => {
      updateMediaByMediaIdCallback(media.id, (media: ImportedMedia) => {
        if (media.fakeTranscribeProgressInterval !== interval) {
          clearInterval(interval);
          return media;
        }

        const tempPercentage = (media.percentage || 0) + 1;
        if (tempPercentage > MAXIMUM_PROGRESS_PERCENTAGE) {
          clearInterval(interval);
        }

        const nextPercentage =
          tempPercentage >= MAXIMUM_PROGRESS_PERCENTAGE ? MAXIMUM_PROGRESS_PERCENTAGE : tempPercentage;

        return {
          ...media,
          progressText: MediaStatus.Transcribing,
          percentage: Math.round(nextPercentage),
        };
      });
    }, tick);

    return interval;
  }

  function startFakeTranscodeProcessInterval(media: ImportedMedia) {
    const duration = calculateExpectedTime(media.durationInSeconds || 0, media.type);
    const currentPercentage = media.percentage || 0;
    const remainingTime = duration / 2;

    // if (media?.start_time_transcription) {
    //   const diffNowAndUploadedTime = moment().diff(moment(media.start_time_transcription), 'seconds');

    //   remainingTime -= diffNowAndUploadedTime;
    //   if (remainingTime <= 0) remainingTime = 0;

    //   currentPercentage = diffNowAndUploadedTime * (MAXIMUM_PROGRESS_PERCENTAGE / remainingTime);
    //   if (currentPercentage >= MAXIMUM_PROGRESS_PERCENTAGE) currentPercentage = MAXIMUM_PROGRESS_PERCENTAGE;

    //   updateMediaByMediaId(media.id, {
    //     ...media,
    //     progressText: MediaStatus.Transcribing,
    //     percentage: Math.round(currentPercentage),
    //   });
    // }

    if (remainingTime <= 0 || currentPercentage >= MAXIMUM_PROGRESS_PERCENTAGE) return;

    const timeToCompleteProcessInSeconds = duration;

    if (timeToCompleteProcessInSeconds <= 0) return;

    const tick = (1 / MAXIMUM_PROGRESS_PERCENTAGE) * timeToCompleteProcessInSeconds * 1000;

    const interval = setInterval(() => {
      updateMediaByMediaIdCallback(media.id, (media: ImportedMedia) => {
        if (media.fakeTranscodeProgressInterval !== interval) {
          clearInterval(interval);
          return media;
        }

        const tempPercentage = (media.percentage || 0) + 1;
        if (tempPercentage > MAXIMUM_PROGRESS_PERCENTAGE) {
          clearInterval(interval);
        }

        const nextPercentage =
          tempPercentage >= MAXIMUM_PROGRESS_PERCENTAGE ? MAXIMUM_PROGRESS_PERCENTAGE : tempPercentage;

        return {
          ...media,
          progressText: MediaStatus.Transcoding,
          percentage: Math.round(nextPercentage),
        };
      });
    }, tick);

    return interval;
  }

  function handleLoadFiles(metadata: FileMetadata[]) {
    metadata.map(async (meta) => {
      const foundDuplicateMediaNameWhenUploading = mediasState.some(
        (item) => item.name === meta.fileName && item.status === MediaStatus.Uploading,
      );

      if (foundDuplicateMediaNameWhenUploading) return;

      const status = meta.type === TypeInputFile.Local ? MediaStatus.Uploading : MediaStatus.Transcoding;

      const isLocalFile = meta.type === TypeInputFile.Local;
      const isDriveFile = meta.type === TypeInputFile.Drive;
      const isDropbox = meta.type === TypeInputFile.Dropbox;

      const { data: createMediaData } = await createMediaPromise(client, {
        variables: {
          filename: meta.fileName || '',
          frameFilePath: 'img/poster-video.png',
          projectId: projectId,
          type: meta.type,
        },
      });

      const createdMedia = {
        ...createMediaData?.createMedia,
        metadata: meta,
        status: status,
      };

      const mediaId = createdMedia.id as string;

      if (isLocalFile) {
        // const { data: createSignUrlAwsData } = await createSignUrlAwsPromise(client, {
        //   variables: {
        //     filename: createdMedia.name as string,
        //     mimeType: createdMedia.metadata.file?.type as string,
        //     mediaId: createdMedia.id as string,
        //   },
        // });

        // if (createSignUrlAwsData?.createSignUrlAws) {
        //   const { key, url } = createSignUrlAwsData?.createSignUrlAws;

        //   uploadMediaToS3({
        //     key,
        //     url,
        //     media: createdMedia,
        //   });
        // }

        const videoUploaderOptions = {
          client: client,
          fileName: createdMedia.name as string,
          file: createdMedia?.metadata?.file as File,
          mediaId,
        };

        const uploader = new Uploader(videoUploaderOptions);

        // updateMediaByMediaId(mediaId, {
        //   percentage: 0,
        //   uploader,
        //   status: MediaStatus.Uploading,
        //   progressText: MediaStatus.Uploading,
        // });

        uploader
          .onProgress(({ percentage: newPercentage }: any) => {
            const percentage = Math.round(newPercentage * 0.5) || 0;
            updateMediaByMediaId(mediaId, {
              percentage,
              status: MediaStatus.Uploading,
              progressText: MediaStatus.Uploading,
              uploader,
            });
          })
          .onComplete(({ url, key }) => {
            updateUploadedMedia({
              variables: {
                mediaId: mediaId,
                fileName: key as string,
                type: TypeInputFile.Local,
                durationInSeconds: createdMedia.metadata?.durationInSeconds,
                fileSize: `${createdMedia.metadata?.fileSize}`,
              },
            });
          })
          .onError((error: any) => {
            console.error(error);
          });

        uploader.start();

        return;
      }

      if (isDriveFile || isDropbox) {
        updateUploadedMedia({
          variables: {
            mediaId: createdMedia.id as string,
            fileName: createdMedia.name as string,
            type: meta.type,
            fileSize: `${meta.fileSize}`,
            linkToFile: meta.linkToFile,
            ...(isDriveFile ? { accessToken: accessTokenGD } : {}),
          },
        });

        return;
      }
    });
  }

  function getDuration(file: File) {
    return new Promise<number>((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(Math.round(video.duration));
      };

      video.onerror = function (err) {
        reject(err);
      };

      video.src = URL.createObjectURL(file);
    });
  }

  function handleUploadFilesWithMux(metadata: FileMetadata[]) {
    metadata.map(async (meta) => {
      const foundDuplicateMediaNameWhenUploading = mediasState.some(
        (item) => item.name === meta.fileName && item.status === MediaStatus.Uploading,
      );

      if (foundDuplicateMediaNameWhenUploading) return;

      const isLocalFile = meta.type === TypeInputFile.Local;
      const isDriveFile = meta.type === TypeInputFile.Drive;
      const isDropbox = meta.type === TypeInputFile.Dropbox;

      const status = isLocalFile ? MediaStatus.Uploading : MediaStatus.Downloading;
      const progressText = isLocalFile ? MediaStatus.Uploading : MediaStatus.Downloading;

      const { data: createMediaData } = await createMediaPromise(client, {
        variables: {
          filename: meta.fileName || '',
          frameFilePath: 'img/poster-video.png',
          type: meta.type,
          projectId: projectId,
          progressText: progressText,
        },
      });

      const createdMedia = {
        ...createMediaData?.createMedia,
        metadata: meta,
        status: status,
      };

      const mediaId = createdMedia.id as string;

      // if ((isDriveFile || isDropbox) && meta.getFile) {
      //   const fileResult = await meta.getFile((percentage) => {
      //     updateMediaByMediaId(mediaId, {
      //       percentage,
      //       status: MediaStatus.Importing,
      //       progressText: MediaStatus.Importing,
      //     });
      //   });

      //   if (fileResult) {
      //     meta.file = fileResult;
      //     meta.durationInSeconds = await getDuration(fileResult);
      //   }
      // }

      if (isLocalFile) {
        // const { data: createSignUrlAwsData } = await createSignUrlAwsPromise(client, {
        //   variables: {
        //     filename: createdMedia.name as string,
        //     mimeType: createdMedia.metadata.file?.type as string,
        //     mediaId: createdMedia.id as string,
        //   },
        // });

        // if (createSignUrlAwsData?.createSignUrlAws) {
        //   const { key, url } = createSignUrlAwsData?.createSignUrlAws;

        //   uploadMediaToS3({
        //     key,
        //     url,
        //     media: createdMedia,
        //   });
        // }

        const videoUploaderOptions = {
          client: client,
          fileName: createdMedia.name as string,
          file: createdMedia?.metadata?.file as File,
          mediaId,
        };

        const uploader = new Uploader(videoUploaderOptions);

        // updateMediaByMediaId(mediaId, {
        //   percentage: 0,
        //   uploader,
        //   status: MediaStatus.Uploading,
        //   progressText: MediaStatus.Uploading,
        // });

        uploader
          .onProgress(({ percentage: newPercentage }: any) => {
            // console.log(`${newPercentage}%`);
            const percentage = Math.round(newPercentage) || 0;
            updateMediaByMediaId(mediaId, {
              percentage,
              status: MediaStatus.Uploading,
              progressText: MediaStatus.Uploading,
              uploader,
            });
          })
          .onComplete(({ url, key }) => {
            notifySeverMediaUploadToMux({
              variables: {
                mediaId: mediaId,
                fileName: key as string,
                type: TypeInputFile.Local,
                durationInSeconds: createdMedia.metadata?.durationInSeconds,
                fileSize: `${createdMedia.metadata?.fileSize}`,
              },
            });
          })
          .onError((error: any) => {
            updateMediaByMediaId(mediaId, { status: MediaStatus.Failed });
            updateStatusMediaFail({ variables: { mediaId: mediaId } });

            console.error(error);
          });

        uploader.start();

        return;
      }

      if (isDriveFile || isDropbox) {
        notifySeverMediaUploadToMux({
          variables: {
            mediaId: createdMedia.id as string,
            fileName: createdMedia.name as string,
            type: meta.type,
            fileSize: `${meta.fileSize}`,
            linkToFile: meta.linkToFile,
            ...(isDriveFile ? { accessToken: 'Bearer ' + accessTokenGD } : {}),
          },
        });
      }
    });
  }

  function uploadMediaToS3({ key, url, media }: { url: string; key: string; media: ImportedMedia }) {
    const mediaId = media.id;
    const blob = new Blob([media?.metadata?.file as File], {
      type: media?.metadata?.file?.type as string,
    });
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', blob.type);

    function onProgress(event: any) {
      const percentage = Math.round((event.loaded / event.total) * (!useMuxService ? 50 : 100)) || 0;
      updateMediaByMediaId(mediaId, { percentage, status: MediaStatus.Uploading, progressText: MediaStatus.Uploading });
    }

    xhr.upload.addEventListener('progress', onProgress, false);
    xhr.addEventListener(
      'abort',
      () => {
        deleteFileUpload({
          variables: {
            mediaId: mediaId,
          },
        });
        console.log(`abort mediaId=${mediaId}`);
      },
      false,
    );

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        console.log(`Upload Media=${mediaId} successfully`);

        if (useMuxService) {
          notifySeverMediaUploadToMux({
            variables: {
              mediaId: mediaId,
              fileName: key,
              type: media.metadata?.type,
              durationInSeconds: media.metadata?.durationInSeconds,
              fileSize: `${media.metadata?.fileSize}`,
            },
          });
        } else {
          updateUploadedMedia({
            variables: {
              mediaId: mediaId,
              fileName: key,
              type: TypeInputFile.Local,
              durationInSeconds: media.metadata?.durationInSeconds,
              fileSize: `${media.metadata?.fileSize}`,
            },
          });
        }
      }
    };

    updateMediaByMediaId(mediaId, {
      percentage: 0,
      xhr,
      status: MediaStatus.Uploading,
      progressText: MediaStatus.Uploading,
    });

    xhr.onerror = function () {
      updateMediaByMediaId(mediaId, { status: MediaStatus.Failed });
    };

    xhr.send(blob);
  }

  function onTranscodingProgress(data: TOnMediaStatusChangedSubscriptionResult['onMediaStatusChanged']) {
    const { mediaId, status } = data;
    const { transcodingPercentageValue } = data;
    const currentPercentage = transcodingPercentageValue || 0;

    const media = getMediaById(mediaId);

    if (!media) return;

    let newPercentage = media.percentage || 0;

    if (!media.isCancel && currentPercentage > newPercentage) {
      newPercentage = Math.round(currentPercentage);
      newPercentage = newPercentage > MAXIMUM_PROGRESS_PERCENTAGE ? MAXIMUM_PROGRESS_PERCENTAGE : newPercentage;

      updateMediaByMediaId(mediaId, {
        percentage: newPercentage,
        progressText: status,
        status,
      });
    }
  }

  function onSubTranslateFile(data: TProcessTranslateMultipleMediaSubscriptionResult['processTranslateMultipleMedia']) {
    const { progress, mediaIdTranslate } = data;
    if (!mediaIdTranslate) return;

    const media = getMediaById(mediaIdTranslate);

    if (!media) return;

    updateMediaByMediaId(mediaIdTranslate, {
      percentage: progress,
      progressText: MediaStatus.Translating,
      status: MediaStatus.Translating,
    });
  }

  function cancelUploadingMedia(media: ImportedMedia) {
    updateMediaByMediaId(media.id, { isCancel: true });

    if (media.xhr) {
      media.xhr.abort();
    }

    if (media.uploader) {
      media.uploader.abort();
    }

    if (media.fakeTranscribeProgressInterval) {
      clearInterval(media.fakeTranscribeProgressInterval);
    }

    if (media.fakeTranscodeProgressInterval) {
      clearInterval(media.fakeTranscodeProgressInterval);
    }

    deleteFileUpload({
      variables: {
        mediaId: media.id,
      },
    });
  }

  async function onStartTranscribe(media: ImportedMedia) {
    const tempMedia = _.cloneDeep(media);

    if (!media.durationInSeconds) {
      // for upload type DRIVE and DROPBOX
      const {
        data: { getAssemblyMedia },
      } = await getAssemblyMediaPromise(client, { variables: { mediaId: media.id } });

      tempMedia.durationInSeconds = getAssemblyMedia?.durationInSeconds || 0;
    }

    tempMedia.percentage = 0;
    const language = 'en-US';
    const defaultSpeechApi = '4'; // 7 is avflow service - 4 is ss-api Speechmatics service
    // const interval = startFakeTranscribeProcessInterval(tempMedia);

    updateMediaByMediaId(tempMedia.id, {
      // ...(!interval ? { percentage: MAXIMUM_PROGRESS_PERCENTAGE } : { percentage: 0 }),
      // fakeTranscribeProgressInterval: interval,
      progressText: MediaStatus.Transcribing,
      status: MediaStatus.Transcribing,
    });

    startTranscribe({
      variables: {
        mediaId: tempMedia?.id,
        language: language,
        defaultSpeechApi: defaultSpeechApi,
      },
    });

    clearTranscodeIntervalMedia(media.id);
  }

  function onTranscribeDone(media: ImportedMedia) {
    updateMediaByMediaId(media.id, {
      status: MediaStatus.Transcribed,
    });
  }

  function setListFileMedia(medias: ImportedMedia[]) {
    let newMedias = [];

    newMedias = medias.map((media) => {
      // if (media.status === MediaStatus.Transcribing) {
      //   const interval = startFakeTranscribeProcessInterval(media);

      //   return {
      //     ...media,
      //     ...(!interval ? { percentage: MAXIMUM_PROGRESS_PERCENTAGE } : {}),
      //     fakeTranscribeProgressInterval: interval,
      //   };
      // }

      const foundMediaState = mediasState.find((state) => state.id === media.id);

      return {
        ...(foundMediaState ? foundMediaState : {}),
        ...media,
      };
    });

    const sortedMedias = newMedias.sort((media) =>
      [
        MediaStatus.Downloading,
        MediaStatus.Downloaded,
        MediaStatus.Uploading,
        MediaStatus.Transcoding,
        MediaStatus.Transcribing,
        MediaStatus.Ready,
      ].includes(media.status)
        ? -1
        : 1,
    );

    setMediasState(sortedMedias);
  }

  function onStartTranslate(media: ImportedMedia, listTranslate: Language[]) {
    // const listCode = [] as string[];
    // listTranslate.map(language => {
    //   if (!language.translation_code) return;
    //   listCode.push(language.translation_code)
    // });
    // translateMedia({ variables: { mediaId: media.id, translateCode: listCode } })
  }

  // function onChargeCreditBefore(media: ImportedMedia, type: MediaAction) {
  //   updateMediaByMediaId(media.id, {
  //     typeJob: type,
  //     // isCheckCharge: true,
  //   });

  //   const language = 'en-US';
  //   const defaultSpeechApi = '7'; // 7 is avflow service

  //   chargeCredit({
  //     variables: {
  //       mediaId: media.id,
  //       language: language,
  //       defaultSpeechApi: defaultSpeechApi,
  //       type: type,
  //     },
  //   });
  // }

  function addMedias(medias: ImportedMedia[] = []) {
    setMediasState((preState: any) => [...preState, ...medias]);
  }

  function addMedia(media = {}) {
    setMediasState((preState: any) => preState.concat(media));
  }

  function addToTopMedia(media = {}) {
    setMediasState((preState) => [media, ...preState]);
  }

  function updateMediaAtIdx(idx: number, properties = {}) {
    setMediasState((preState: any) => {
      const newState = [...preState];
      if (!newState[idx]) return newState;

      newState[idx] = {
        ...newState[idx],
        ...properties,
      };

      return newState;
    });
  }

  function updateMediaByMediaId(mediaId: string, properties: any = {}) {
    setMediasState((preState: any) => {
      const newState = [...preState];
      const idx = newState.findIndex((item) => item.id === mediaId);

      if (idx === -1) return newState;

      newState[idx] = {
        ...newState[idx],
        ...properties,
      };

      const cacheIdentifier = client.cache.identify({
        __typename: 'Media',
        id: mediaId,
      });

      if (typeof properties.percentage !== 'undefined') {
        client.cache.modify({
          id: cacheIdentifier,
          fields: {
            percentage(cacheValue: string, context: any) {
              return properties.percentage;
            },
            progressText(cacheValue: string, context: any) {
              return properties.progressText || cacheValue;
            },
            // status(cacheValue: string, context: any) {
            //   return properties.status || cacheValue;
            // },
          },
        });
      }

      return newState;
    });
  }

  function updateMediaByMediaIdCallback(mediaId: string, callback: Function) {
    setMediasState((preState: any) => {
      const newState = [...preState];
      const idx = newState.findIndex((item) => item.id === mediaId);
      if (idx === -1) return newState;

      newState[idx] = callback(newState[idx]);
      return newState;
    });
  }

  function getMediaById(mediaId: string) {
    return mediasState.find((item) => item.id === mediaId);
  }

  function deleteMediaById(mediaId: string) {
    setMediasState((prev) => prev.filter((item) => item.id !== mediaId));
  }

  // function onCloseModalInsufficientCredit(media: ImportedMedia) {
  //   updateMediaByMediaId(media.id, {
  //     isChargeFail: false,
  //     isCheckCharge: false,
  //   });
  // }

  function onAmendMedia(mediaId: string, folderId: string | null) {
    updateMediaByMediaId(mediaId, { project_folder_id: folderId });
  }

  function clearTranscribeIntervalMedia(mediaId: string) {
    const foundMedia = mediasState.find((m) => m.id === mediaId);
    if (!foundMedia) return;

    clearInterval(foundMedia.fakeTranscribeProgressInterval);

    updateMediaByMediaId(mediaId, {
      fakeTranscribeProgressInterval: undefined,
    });
  }

  function clearTranscodeIntervalMedia(mediaId: string) {
    const foundMedia = mediasState.find((m) => m.id === mediaId);
    if (!foundMedia) return;

    clearInterval(foundMedia.fakeTranscodeProgressInterval);

    updateMediaByMediaId(mediaId, {
      fakeTranscodeProgressInterval: undefined,
    });
  }

  return (
    <Provider
      value={{
        mediasState,
        lastTranscribe,
        accessTokenGD,
        useMuxService,
        setListFileMedia,
        setAccessTokenGD,
        setLastTranscribe,
        handleLoadFiles,
        handleUploadFilesWithMux,
        onStartTranslate,
        onTranscribeDone,
        onStartTranscribe,
        cancelUploadingMedia,
        onAmendMedia,
        onSubTranslateFile,
      }}
    >
      {children}
    </Provider>
  );
};

const useSidebar = () => {
  const context = useContext(sideBarStore);
  if (context === undefined) throw new Error(`useSidebar must be called within SidebarContextProvider`);
  return context;
};
export { sideBarStore, useSidebar, SideBarProvider };
