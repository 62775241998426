import type * as Apollo from '@apollo/client';
import {
  GetAssemblyMediaDocument,
  TGetAssemblyMediaQueryResult,
  TGetAssemblyMediaQueryVariables,
} from '@root/graphql/generated/operations';

type Result = TGetAssemblyMediaQueryResult;
type Variables = TGetAssemblyMediaQueryVariables;
type Options = Omit<Apollo.QueryOptions<Variables>, 'query'>;
const document = GetAssemblyMediaDocument;

export const getAssemblyMediaPromise = async (
  client: Apollo.ApolloClient<object>,
  baseOptions?: Options,
): Promise<Apollo.ApolloQueryResult<Result>> => {
  const result = await client.query<Result, Variables>({
    query: document,
    ...baseOptions,
  });

  return result;
};
