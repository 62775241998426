import type * as Apollo from '@apollo/client';
import {
  GetUserProfileDocument,
  TGetUserProfileQueryResult,
  TGetUserProfileQueryVariables,
} from '@root/graphql/generated/operations';

type Result = TGetUserProfileQueryResult;
type Variables = TGetUserProfileQueryVariables;
type Options = Omit<Apollo.QueryOptions<TGetUserProfileQueryVariables>, 'query'>;
const document = GetUserProfileDocument;

export const getUserProfilePromise = async (
  client: Apollo.ApolloClient<object>,
  baseOptions?: Options,
): Promise<Apollo.ApolloQueryResult<Result>> => {
  const result = await client.query<Result, Variables>({
    query: document,
    ...baseOptions,
  });

  return result;
};
