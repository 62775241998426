import { createMultipartPreSignedUrlsPromise, finalizeMultipartUploadPromise } from '@root/graphql/operations';
import { TPartSignedUrl } from '@root/graphql/generated/operations';
import type * as Apollo from '@apollo/client';

type UploaderOPtions = {
  client: Apollo.ApolloClient<object>;
  mediaId: string;
  file: File;
  fileName: string;
  chunkSize?: number;
  threadsQuantity?: number;
};

type OnCompleteFn = ({ url, key }: { url?: string | null; key?: string | null }) => void;
type OnErrorFn = (error: unknown) => void;
type OnProgressFn = (input: { sent: number; total: number; percentage: number }) => void;

export class Uploader {
  client: Apollo.ApolloClient<object>;
  mediaId: string;
  chunkSize: number;
  threadsQuantity: any;
  file: File;
  fileName: string;
  aborted: boolean;
  uploadedSize: number;
  progressCache: Record<number, number>;
  activeConnections: Record<number, XMLHttpRequest>;
  parts: TPartSignedUrl[];
  uploadedParts: { PartNumber: number; ETag: string }[];
  fileId?: string | null;
  fileKey?: string | null;
  onProgressFn: OnProgressFn;
  onErrorFn: OnErrorFn;
  onCompleteFn: OnCompleteFn;

  constructor(options: UploaderOPtions) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.client = options.client;
    this.mediaId = options.mediaId;

    this.onProgressFn = () => this;
    this.onErrorFn = () => this;
    this.onCompleteFn = () => this;
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      let fileName = this.fileName;
      const ext = this.file.name.split('.').pop();

      if (ext) {
        fileName += `.${ext}`;
      }

      const numberOfParts = Math.ceil(this.file.size / this.chunkSize);

      const urlsResponse = await createMultipartPreSignedUrlsPromise(this.client, {
        variables: {
          filename: this.fileName,
          mimeType: '',
          mediaId: this.mediaId,
          parts: numberOfParts,
        },
      });

      const response = urlsResponse.data?.createMultipartPreSignedUrls;
      const newParts = response?.parts || [];
      this.fileId = response?.fileId;
      this.fileKey = response?.fileKey;

      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();

    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  async complete(error?: any) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn(error);
    }
  }

  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      const response = await finalizeMultipartUploadPromise(this.client, {
        variables: {
          input: videoFinalizationMultiPartInput,
        },
      });

      const data = response.data?.finalizeMultipartUpload;

      this.onCompleteFn({ url: data?.url, key: data?.key });
    }
  }

  sendChunk(chunk: any, part: any, sendChunkStarted: any) {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: any, event: any) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file: any, part: any, sendChunkStarted: any) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.partNumber - 1] = new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.partNumber - 1);

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            const status = xhr.status;

            if (status >= 200 && status < 400) {
              const ETag = xhr.getResponseHeader('ETag');

              if (ETag) {
                const uploadedPart = {
                  PartNumber: part.partNumber,
                  ETag: ETag.replaceAll('"', ''),
                };

                this.uploadedParts.push(uploadedPart);

                resolve(xhr.status);
                delete this.activeConnections[part.partNumber - 1];
              }
            } else {
              reject(new Error('Oh no! There has been an error with the request!'));
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.send(file);

        return;
      }

      reject(new Error(`fileId and fileKey are required.`));
    });
  }

  onProgress(onProgress: OnProgressFn) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: OnErrorFn) {
    this.onErrorFn = onError;
    return this;
  }

  onComplete(onComplete: OnCompleteFn) {
    this.onCompleteFn = onComplete;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
