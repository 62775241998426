import type * as Apollo from '@apollo/client';
import {
  TCreateSignUrlAwsMutationResult,
  TCreateSignUrlAwsMutationVariables,
  CreateSignUrlAwsDocument,
} from '@root/graphql/generated/operations';

type Result = TCreateSignUrlAwsMutationResult;
type Variables = TCreateSignUrlAwsMutationVariables;
type Options = Omit<Apollo.MutationOptions<Result, Variables>, 'mutation'>;
const document = CreateSignUrlAwsDocument;

export const createSignUrlAwsPromise = async (
  client: Apollo.ApolloClient<object>,
  baseOptions?: Options,
): Promise<Apollo.FetchResult<Result>> => {
  const result = await client.mutate<Result, Variables>({
    mutation: document,
    ...baseOptions,
  });

  return result;
};
