import React, { createContext, useState, useEffect, useRef, useContext } from 'react';
import { Alert } from 'reactstrap';

type AlertProviderProps = {
  children: any;
};

enum AlertColorEnum {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  light = 'light',
  dark = 'dark',
}

const initialState = {
  showAlert: ({ type, message }: { type: AlertColorEnum; message: string | React.ReactElement }) => {},
};

const alertStore = createContext(initialState);
const { Provider } = alertStore;

function AlertProvider({ children }: AlertProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const alertConfig = useRef<{ type: AlertColorEnum; message: string | React.ReactElement; timeout?: number }>({
    message: '',
    type: AlertColorEnum.info,
    timeout: 3000,
  });
  const alertTimeout = useRef<any>(null);

  useEffect(() => {
    if (isOpen && alertTimeout.current === null) {
      alertTimeout.current = setTimeout(() => {
        alertTimeout.current = null;
        setIsOpen(false);
      }, alertConfig.current.timeout);
    }

    if (!isOpen && alertTimeout.current !== null) {
      clearTimeout(alertTimeout.current);
      alertTimeout.current = null;
    }

    return () => {
      clearTimeout(alertTimeout.current);
      alertTimeout.current = null;
    };
  }, [isOpen]);

  function showAlert(args: { type: AlertColorEnum; message: string | React.ReactElement; timeout?: number }) {
    const { type, message, timeout } = args;
    alertConfig.current = { type, message, timeout: timeout ?? alertConfig.current.timeout };
    setIsOpen(true);
  }

  function toggle() {
    setIsOpen((prev) => !prev);
  }

  return (
    <Provider value={{ showAlert }}>
      <Alert color={alertConfig.current.type} isOpen={isOpen} toggle={toggle}>
        {alertConfig.current.message}
      </Alert>
      {children}
    </Provider>
  );
}

const useAlert = () => {
  const context = useContext(alertStore);
  if (context === undefined) throw new Error(`useAlert must be called within AlertContextProvider`);
  return context;
};

export { AlertColorEnum, alertStore, AlertProvider, useAlert };
